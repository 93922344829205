import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chart from "react-apexcharts";

import TopContent from "../components/TopContent";
import BreadCrumbList from "../components/BreadCrumbList";
import api from "../services/api";
import { format } from "date-fns";

import { jsPDF } from "jspdf";

// import AnalyticsLesson from '../components/AnalyticsLesson'

// quiz questions

const WeekDetailsAnalytics = ({ match }) => {
  const [title, setTitle] = useState("");

  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Analytics",
      to: "/analytics",
    },
    {
      name: title,
      to: `/analytics/details/${match.params.classId}/${match.params.id}`,
    },
  ];
  const options1 = {
    chart: {
      type: "radialBar",
      height: "220px",
      width: "220px",
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#000000",
            lineHeight: "15px",

            fontSize: "12px",
            fontWeight: "400",
          },
          value: {
            color: "#000000",

            show: true,
            fontWeight: 400,
            fontSize: "36px",
            lineHeight: "44px",
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#979797",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
      },
    },
    colors: ["#EF5B2C", "#0090FF"],
    stroke: {
      lineCap: "round",
    },
    labels: ["Average Correct Rate"],
  };
  const series = [70];
  const ExpandMoreIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.70703 16.707L3.29282 15.2928L11.9999 6.58571L20.707 15.2928L19.2928 16.707L11.9999 9.41414L4.70703 16.707Z"
          fill="black"
          fill-opacity="0.2"
        />
      </svg>
    );
  };

  // get questions
  const [questions, setQuestions] = useState();
  const weeklyQuizData = async () => {
    try {
      const data = await api.get(
        `/teacher/week/performance/summary/${match.params.classId}/${match.params.id}`
      );
      console.log(data);
      setQuestions(data);
      // setTitle(data.contentDetail[0].title)
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    weeklyQuizData();
  }, [match]);
  function converter(seconds) {
    // var days = Math.floor(seconds / (24 * 60 * 60))
    // seconds -= days * (24 * 60 * 60)
    // var hours = Math.floor(seconds / (60 * 60))
    // seconds -= hours * (60 * 60)
    // var minutes = Math.floor(seconds / 60)
    // seconds -= minutes * 60
    // return (
    //   (0 < days ? days + ' day, ' : '') +
    //   (0 < hours ? hours + 'h, ' : '') +
    //   (0 < minutes ? minutes + 'm and ' : '') +
    //   seconds +
    //   's'
    // )
    return Math.abs(seconds);
  }
  // generate pdf
  const generatePdf = () => {
    const report = new jsPDF("portrait", "pt", "a4");
    console.log("here is the content", document.querySelector("#questions"));
    report.html(document.querySelector("#questions")).then(() => {
      report.save("report.pdf");
    });
  };
  const getProgresspercent = (noOfChoose, totalStudent) => {
    console.log("choose is", noOfChoose, totalStudent);
    {
      return ((noOfChoose / totalStudent) * 100).toFixed(0);
    }
  };
  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="analytics" />
        <div className="main_section">
          <div className="main_section_inner main_section_inner_chat">
            {/* <TopContent /> */}
            <div
              className="middle-Content middle-Content-Student middle-Content-Student-new"
              style={{ position: "relative" }}
            >
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div
                className="tabs-cover tabs-cover-new d-flex-row"
                style={{ justifyContent: "flex-end" }}
              >
                <div className="select-section d-flex-row">
                  <div className="rightSidebar-inner analytics-topbar">
                    <div className="inner-box">
                      <div className="top">
                        <div className="left">
                          <div className="date-classroom">
                            {format(new Date(), "dd MMMM")}
                          </div>
                          <div
                            className="date-classroom date-classroom-year"
                            style={{ textAlign: "left" }}
                          >
                            {format(new Date(), "yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="weeklyquiz-container">
                <div className="header d-flex-row" style={{ gap: "400px" }}>
                  Module Analytics
                  {/* <div
                    className="btn-analytics btn-new-export"
                    onClick={generatePdf}
                  >
                    Export
                  </div> */}
                </div>
                <div id="questions">
                  {questions !== undefined &&
                    questions?.avgCorrectRate.length > 0 &&
                    questions?.avgCorrectRate.map((item, id) => (
                      <div className="content analyticsSystem-outer">
                        <div
                          className="accordion-content"
                          style={{ width: "70%" }}
                        >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <span className="accordion-heading-1">
                                Q.{id + 1}
                              </span>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="accordion-inner-content">
                                <div className="leftContent">
                                  <Chart
                                    options={options1}
                                    // series={[
                                    //   questions?.totalAvgCorrect[id].toFixed(
                                    //     1
                                    //   ) * 100,
                                    // ]}
                                    series={[
                                      questions?.totalAvgCorrect[id]
                                        ? (
                                            questions?.totalAvgCorrect[id] * 100
                                          ).toFixed(0)
                                        : 0,
                                    ]}
                                    type="radialBar"
                                    height={220}
                                    width={220}
                                  />
                                  <div className="time-content">
                                    <span>Average Time Spent</span>
                                    <span>
                                      {converter(
                                        questions.averageTime[id]?.time.toFixed(
                                          2
                                        )
                                      )}{" "}
                                      s
                                    </span>
                                  </div>
                                </div>

                                <div className="right-content">
                                  <div className="questions-section d-flex-column">
                                    <div className="question">
                                      <div className="text">
                                        {item.question} ?
                                      </div>
                                      {/* <div className='progress-cover'>
                                  <div className='percentage'>1615(58%)</div>
                                </div> */}
                                    </div>
                                    {item.options.map((option, id) => (
                                      <div className="question">
                                        <div className="text">
                                          {option.name}
                                        </div>
                                        <div
                                          className="progress-cover"
                                          style={{
                                            width: `${getProgresspercent(
                                              option?.noOfChoose,
                                              questions?._totalStudent
                                            )}%`,
                                          }}
                                        >
                                          <div className="percentage">
                                            {getProgresspercent(
                                              option?.noOfChoose,
                                              questions?._totalStudent
                                            ) > 0 &&
                                              `${getProgresspercent(
                                                option?.noOfChoose,
                                                questions?._totalStudent
                                              )}% `}
                                            {" ("}
                                            {option.noOfChoose}
                                            {")"}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekDetailsAnalytics;

import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopContent from "../../components/TopContent";
import BreadCrumbList from "../../components/BreadCrumbList";
import RightSidebar from "../../components/RightSidebar";
import ChatGroupBox from "../../components/ChatGroupBox";
import ChatPerson from "../../assets/chatPerson.svg";
import UploadIcon from "../../assets/uploadIcon1.svg";
import UploadIcon1 from "../../assets/uploadIcon.svg";
import faceEmoji from "../../assets/faceEmoji.svg";
import uploadIconFinal from "../../assets/uploadIconFinal.svg";
import io from "socket.io-client";
import TimeAgo from "javascript-time-ago";
import search from "../../assets/search.svg";

import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { format } from "date-fns";
import api from "../../services/api";
import en from "javascript-time-ago/locale/en.json";
import config from "../../config";
import noAvatar from "../../assets/noImage.png";

TimeAgo.addDefaultLocale(en);
const teacherToken = JSON.parse(localStorage.getItem("userData"));
const token = teacherToken ? teacherToken.accessToken : "";

const socket = io(config.app.socketUrl, {
  query: {
    auth_token: token,
  },
});
const Chat = () => {
  const timeAgo = new TimeAgo("en-US");
  console.log("here", config.app.apiUrl);

  // get all chats

  const scrollRef = useRef();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [emojiDisplay, setEmojiDisplay] = useState(false);
  const [chatText, setChatText] = useState("");
  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject.emoji);
    setChatText(chatText + emojiObject.emoji);
    // setEmojiDisplay(false)
  };
  const [refresh, setRefresh] = useState(false);
  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "Chat",
      to: "/chat",
    },
  ];
  const teacherId = JSON.parse(localStorage.getItem("userData")).user._id;
  const teacherName = JSON.parse(localStorage.getItem("userData")).user.name;

  const [onlineUsers, setOnlineUsers] = useState([]);

  const [studentName, setStudentName] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [studentToChat, setStudentToChat] = useState();
  const [studentImage, setStudentImage] = useState();
  const [selectedStudentAvatar, setSelectedStudentAvatar] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  useEffect(() => {
    const getAllChats = async () => {
      try {
        const data = await api.get(`/user/chat/history/${studentToChat}`);
        setChatHistory(data?.data);
        console.log(data?.data);
        setSelectedStudentAvatar(data?.data[0].receiver.avatar);
      } catch (error) {
        console.log(error);
      }
    };
    if (studentToChat) {
      getAllChats();
    }
  }, [studentToChat]);

  console.log(selectedStudentAvatar);

  const [chatPersons, setChatPersons] = useState([]);
  useEffect(() => {
    const getAllChats = async () => {
      try {
        const data = await api.get(`/user/chat/history `);
        console.log("data is", data);

        if (data.data.length > 0) {
          setChatPersons(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllChats();
  }, []);
  const searchStudent = async (e) => {
    setStudentName(e.target.value);
    if (e.target.value !== "") {
      const data = await api.get(`/teacher/search/student/${e.target.value}`);
      // get the searched list

      setStudentList(data.data);
      try {
      } catch (error) {
        console.log(error);
      }
    } else {
      setStudentList([]);
    }
  };

  const studentSet = (id) => {
    setStudentToChat(id);
    setStudentList([]);
    setStudentName("");
  };

  const sendMessage = (e) => {
    console.log("hit hit");
    e.preventDefault();
    if (chatText === "") {
      return;
    }
    const data = {
      // sender: { _id: teacherId },
      user: studentToChat,
      msg: chatText,
    };
    console.log("data is", data);
    socket.emit("message:send", data);
    const msgMock = {
      createdAt: new Date(),
      sender: {
        _id: teacherId,
        name: teacherName,
      },
      message: chatText,
    };
    setChatHistory((prev) => {
      return [...prev, msgMock];
    });

    setChatText("");
    setEmojiDisplay(false);
    setRefresh(!refresh);
  };
  useEffect(() => {
    socket.on("message:received", (payload) => {
      console.log("on receive message", payload);
      const msgMock = {
        createdAt: Date.now(),

        sender: {
          _id: payload.user._id,
          name: payload?.user?.name,
        },
        message: payload.msg,
      };
      setChatHistory((prev) => {
        return [...prev, msgMock];
      });
      setRefresh(!refresh);
    });
    // received message
    socket.on("user_err", (payload) => {
      console.log("payoad of error is", payload);
    });
    socket.on("user_response", (payload) => {
      console.log("payoad of response is", payload);
    });
  }, []);

  // scroll to bottom
  useEffect(() => {
    var objDiv = document.getElementById("messages");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [refresh, chatHistory]);

  return (
    <div className="mainContainer">
      <div className="mainDashboard">
        <Sidebar section="chat" />
        <div className="main_section">
          <div className="main_section_inner main_section_inner_chat">
            {/* <TopContent /> */}
            <div className="middle-Content middle-Content-Student">
              <div className="heading-classroom d-flex-row">
                <BreadCrumbList values={values} />
                <div className="search-Container search-Box-new">
                  <div className="search-Box">
                    <input
                      name="student"
                      value={studentName}
                      onChange={searchStudent}
                      type="text"
                      placeholder="Search..."
                    />{" "}
                    <img src={search} alt="Search" />
                  </div>

                  <div className="studentSearch d-flex-column">
                    {studentList.length > 0 && (
                      <div className="student-list_parent" id="myDivId">
                        {studentList.map((Student, id) => (
                          <div
                            key={id}
                            className="studentList"
                            onClick={() => studentSet(Student._id)}
                          >
                            {Student.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="contentChat">
                <div className="rightContent-chat">
                  <ChatGroupBox
                    info={chatPersons}
                    teacher={teacherName}
                    setStudentToChat={setStudentToChat}
                  />
                </div>
                <div className="middleContent-chat">
                  <div className="top">
                    <div className="date">
                      {/* March 9, 2022 */}
                      {format(new Date(), "MMMM dd, yyyy")}
                    </div>
                    <div className="imageInside">
                      <div className="img_inner">
                        <img
                          src={
                            selectedStudentAvatar
                              ? `${config.app.mediaUrl}/api/${selectedStudentAvatar}`
                              : noAvatar
                          }
                          alt="Person"
                        />
                        <span className="greenDot"></span>
                      </div>
                    </div>
                  </div>
                  <div className="messages" id="messages">
                    {console.log("chat history", chatHistory)}
                    {chatHistory.length > 0 &&
                      chatHistory.map((chat, id) => (
                        <>
                          <div
                            className={
                              chat.sender._id === teacherId
                                ? `msg-right`
                                : `msg-left`
                            }
                          >
                            <div className="name">
                              {chat.sender._id === teacherId
                                ? ""
                                : chat?.sender?.name}
                            </div>
                            <div className="msg">{chat?.message}</div>
                            <div className="date">
                              {/* 1:55 PM */}
                              {/* {timeAgo.format(new Date(chat.createdAt))} */}
                            </div>
                          </div>
                          {/* <div className='msg-right'>
                          <div className='name'>Santori</div>
                          <div className='msg'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Id, aut.
                          </div>
                          <div className='date'>1:55 PM</div>
                        </div> */}
                        </>
                      ))}
                  </div>
                  <div className="bottom-Content">
                    {/* <form> */}
                    <form
                      onSubmit={sendMessage}
                      className={`left ${studentToChat && "active-input"}`}
                    >
                      <div>
                        <input
                          type="text"
                          name="chatText"
                          disabled={!studentToChat}
                          value={chatText}
                          placeholder={`${
                            studentToChat
                              ? "Type to send message..."
                              : "Select any student to chat"
                          }`}
                          onChange={(e) => setChatText(e.target.value)}
                        />
                        <div className="icons">
                          {/* <img
                            className='upload'
                            src={uploadIconFinal}
                            alt=''
                          /> */}
                          <img
                            className="emoji"
                            style={{ cursor: "pointer" }}
                            onClick={() => setEmojiDisplay(!emojiDisplay)}
                            src={faceEmoji}
                            alt=""
                          />
                          {emojiDisplay && (
                            <div className="emojiPicker">
                              <Picker
                                disableSearchBar={true}
                                onEmojiClick={onEmojiClick}
                              />
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    </form>

                    <img
                      style={{ cursor: "pointer" }}
                      onClick={sendMessage}
                      src={UploadIcon}
                      alt="Upload Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSidebar";
import noAvatar from "../assets/noImage.png";
import api from "../services/api";
import config from "../config";

const Profile = () => {
  const userData = JSON.parse(localStorage.getItem("userData")).user;
  const [name, setName] = useState(userData?.name);

  const [avatar, setAvatar] = useState({
    file: null,
    url: null,
  });

  const avatarChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setAvatar({
          file,
          url: reader.result,
        });
      };
    }
  };

  const uploadImgHandler = (e) => {
    e.preventDefault();
    const fileUpload = document.getElementById("input_file");
    fileUpload.click();
  };

  console.log(avatar);

  const resetImageHandler = () => {
    setAvatar({
      file: null,
      url: null,
    });
  };

  const resetHandler = () => {
    setName(userData.name);
  };

  const saveHandler = async () => {
    if (avatar.url) {
      const formData = new FormData();
      formData.append("avatar", avatar.file);
      const res = await api.post("/user/profile/avatar", formData);
    }

    try {
      const res = await api.patch("/user/profile", {
        name: name,
      });
      if (res && res.ok) {
        const newData = await api.get("/user/profile");
        if (newData && newData.ok) {
          // console.log(newData.data);
          const oldData = JSON.parse(localStorage.getItem("userData"));
          const schoolId = oldData.user.schoolId;
          oldData.user = newData.data;
          oldData.user.schoolId = schoolId;
          localStorage.setItem("userData", JSON.stringify(oldData));
        }
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mainContainer profilePageMain">
      <div className="mainDashboard">
        <Sidebar section="profile" />
        <div className="main_section">
          <div className="main_section_inner">
            <div className="middle-Content middle-Content-Student">
              <div className="topDiv">
                <div className="avatarMainDiv">
                  <img
                    src={
                      avatar?.url
                        ? avatar.url
                        : userData.avatar
                        ? `${config.app.mediaUrl}/api/${userData.avatar}`
                        : noAvatar
                    }
                    alt="avatar"
                  />
                </div>
                <input
                  type="file"
                  className="input-file"
                  accept="image/png, image/jpeg"
                  id="input_file"
                  onChange={avatarChange}
                  style={{ display: "none" }}
                />
                {avatar.url ? (
                  <button className="cancelBtn" onClick={resetImageHandler}>
                    Reset Change
                  </button>
                ) : (
                  <button className="saveBtn" onClick={uploadImgHandler}>
                    Change Image
                  </button>
                )}
              </div>
              <div className="displayData">
                <span className="label">User Name</span>
                {/* <span>{userData?.name}</span> */}
                <input
                  type="text"
                  name="uname"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="displayData">
                <span className="label">Email</span>
                {/* <span>{userData?.email}</span> */}
                <input
                  type="text"
                  name="email"
                  value={userData?.email}
                  readOnly={true}
                  className="readonly"
                />
              </div>
              <div className="displayData">
                <span className="label">School</span>
                {/* <span>{userData?.schoolId.name}</span> */}
                <input
                  type="text"
                  name="email"
                  value={userData?.schoolId.name}
                  readOnly={true}
                  className="readonly"
                />
              </div>
              <div>
                <button className="saveBtn" onClick={saveHandler}>
                  Save
                </button>
                <button className="cancelBtn" onClick={resetHandler}>
                  Cancel
                </button>
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;

import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import config from "../../config";
import studentImage from "../../assets/enzo.png";
import fireIcon from "../../assets/fire.svg";
import cashIcon from "../../assets/cash.svg";

const LeaderboardCard = ({ id, data }) => {
  const [bgColor, setBgColor] = useState(
    "linear-gradient(89.96deg, #FFCA2A 57.6%, rgba(255, 255, 255, 0) 235.19%)"
  );

  useEffect(() => {
    if (id === 1) {
      setBgColor(
        "linear-gradient(90deg, #EF5B2C 0%, #EF5B2C 20.46%, rgba(239, 91, 44, 0) 193.46%)"
      );
    }
  }, [id]);
  return (
    <Accordion>
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className="leaderBoardCardMainWrapper"
          style={{ paddingTop: "50px", overflow: "hidden", width: "100%" }}
        >
          <div
            className="leaderBoardCardMain d-flex align-items-center col-12 p-3"
            style={{ background: bgColor }}
          >
            <span className="sn" style={{ padding: "10px" }}>
              {id}
            </span>
            <div className="col-4">
              <span className="name">{data.userName}</span>
            </div>
            <div
              className="d-flex col-5"
              style={{ display: "flex", marginLeft: "40px" }}
            >
              <span className="cv mx-3">Company Value:</span>
              <NumberFormat
                value={Math.ceil(data.companyValuation)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                className="cvPrice"
                decimalScale={0}
              />
            </div>
            <img
              src={
                data.customAvatar
                  ? `${config.app.mediaUrl}/api/${data.customAvatar}`
                  : data.gameAvatar?.avatar
                  ? `${config.app.mediaUrl}/api/uploads/admin/character/${data.gameAvatar?.avatar?.largeImg}`
                  : studentImage
              }
              alt="avatar"
              className="avatarImg"
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          className="leaderboardAccordionBody p-3"
          style={{
            background:
              "linear-gradient(90deg, rgba(239, 91, 44, 0.5) 0%, rgba(239, 91, 44, 0.5) 20.46%, rgba(239, 91, 44, 0) 193.46%)",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "10px",
          }}
        >
          {/* First */}
          <div className="card">
            <span className="title">Company Valuation</span>
            <div className="secDiv my-2">
              <img src={fireIcon} alt="valuation icon" />
              <NumberFormat
                value={data?.companyValuation.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                style={{ marginLeft: "10px" }}
                decimalScale={0}
              />
            </div>
          </div>
          {/* First end */}
          {/* Second */}
          <div className="card">
            <span className="title">Company Reputation</span>
            <div className="secDiv my-2">
              <NumberFormat
                value={data?.totalcompanyReputation.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                suffix={"%"}
                decimalScale={0}
              />
            </div>
          </div>
          {/* Second end */}
          {/* Third */}
          <div className="card">
            <span className="title">Number Of Employees</span>
            <div className="secDiv my-2">
              <NumberFormat
                value={Math.floor(data?.totalemployNumber)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={0}
              />
            </div>
          </div>
          {/* Third end */}
          {/* Fourth */}
          <div className="card">
            <span className="title">Employees&apos; Happiness</span>
            <div className="secDiv my-2">
              <NumberFormat
                value={data?.totalemploySatisfaction.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                suffix={"%"}
                decimalScale={0}
              />
            </div>
          </div>
          {/* Fourth end */}
          {/* Fifth */}
          <div className="card">
            <span className="title">Cash Balance</span>
            <div className="secDiv my-2">
              <img src={cashIcon} alt="cash icon" />
              <NumberFormat
                value={data?.totalCash.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                style={{ marginLeft: "10px" }}
                decimalScale={0}
              />
            </div>
          </div>
          {/* Fifth end */}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default LeaderboardCard;

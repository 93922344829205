import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { consoleError } from "../utils/helper";
import api from "../services/api";
import { ToastContainer, toast } from "react-toastify";
import calenderIcon from "../assets/calendar.svg";

const AvailabilityModel = ({ setAvailabilityModel, contetDetails }) => {
  const [newData, setNewDate] = useState(new Date());
  const [lessonStatus, setLessonStatus] = useState(true);

  const [timer, setTimer] = useState({
    hours: "00",
    minutes: "02",
    seconds: "59",
  });

  const timerChangeHandler = (e) => {
    const { name, value } = e.target;
    setTimer((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const toggleLessonStatus = () => {
    setLessonStatus(!lessonStatus);
  };

  const ExampleCustomInput = ({ value, onClick }) => (
    <>
      <button className="example-custom-input" onClick={onClick}>
        {value}
        <img src={calenderIcon} />
      </button>
    </>
  );

  const [successMsg, setSuccessMsg] = useState("");

  const updateAvailableHandler = async () => {
    console.log({ newData, timer });
    const availabilityTime = {
      date: newData,
      // time: timer,
    };
    try {
      const res = await api.post(
        `teacher/setAvailability/add/${contetDetails}`,
        { availabilityTime: availabilityTime }
      );
      if (res && res.ok) {
        toast.success("Auto unlock time set sucessfully.");
        setTimeout(() => {
          setAvailabilityModel(false);
        }, 3000);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  return (
    <>
      <div className="avalabilityModelMainDiv">
        <div className="modelInnerDiv">
          <div className="top">
            <span>Auto Unlock</span>
          </div>
          <div className="middle">
            <div className="datePickerMain">
              <span>Date</span>
              <DatePicker
                selected={newData}
                showTimeSelect={true}
                dateFormat="MMMM d yyyy, h:mm a"
                onChange={(date) => setNewDate(date)}
                customInput={<ExampleCustomInput />}
                timeIntervals={1}
              />
            </div>

            {/* <span>Unlock duration</span>
            <div className="timeDivMain">
              <div className="timeDiv">
                <span>Hours</span>
                <input
                  type="number"
                  name="hours"
                  value={timer.hours}
                  onChange={(e) => timerChangeHandler(e)}
                  maxLength={2}
                />
              </div>
              <span className="colon">:</span>
              <div className="timeDiv">
                <span>Minutes</span>
                <input
                  type="number"
                  name="minutes"
                  value={timer.minutes}
                  onChange={(e) => timerChangeHandler(e)}
                  maxLength={2}
                />
              </div>
              <span className="colon">:</span>
              <div className="timeDiv">
                <span>Seconds</span>
                <input
                  type="number"
                  name="seconds"
                  value={timer.seconds}
                  onChange={(e) => timerChangeHandler(e)}
                  maxLength={2}
                />
              </div>
            </div> */}
          </div>

          <div className="bottom">
            <button
              className="cancel_btn"
              onClick={() => setAvailabilityModel(false)}
            >
              Cancel
            </button>
            <button className="save_btn" onClick={updateAvailableHandler}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AvailabilityModel;

import React from 'react'
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

const ChatGroupBox = ({ info, teacher, setStudentToChat }) => {
  const timeAgo = new TimeAgo('en-US')

  return (
    <>
      {console.log('info is', info)}
      {info.length > 0 &&
        info.map((person, idx) => (
          <div className='chatGroup-box'>
            <div className='top'>
              <div className='header'>
                {person?.name}
                {person.status === 'active' && (
                  <span className='greenDot'></span>
                )}
              </div>
              <div className='time'>
                {/* {timeAgo.format(new Date(person.createdAt))} */}
              </div>
            </div>
            <button
              className='reply-msg'
              onClick={() => setStudentToChat(person?._id)}
            >
              Reply to Message
            </button>
            {/* <div className='assigned-teacher'>{teacher}</div> */}
          </div>
        ))}
    </>
  )
}

export default ChatGroupBox

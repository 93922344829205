import React, { useState, useEffect } from "react";
import topLeftIcon from "../assets/topLeftIcon.svg";
import signOut from "../assets/signOut.svg";
import userEdit from "../assets/userEdit.svg";
import stemUnicornIcon from "../assets/zenb-logo.png";
import sittingGirl from "../assets/sittingGirl.svg";
import Email from "../assets/email.svg";
import eyeIcon from "../assets/eyeIcon.svg";
import userName from "../assets/userName.svg";
import forgotImage from "../assets/forgotImage.svg";
import passwordHide from "../assets/passwordHide.svg";
import { Link } from "react-router-dom";
import api from "../services/api";
import { consoleLog } from "../utils/helper";
import ErrorMessage from "../components/ErrorMessage";
import { ToastContainer, toast } from "react-toastify";

const Authentication = ({ history }) => {
  const [emailAdd, setEmailAdd] = useState("");

  // otp codes
  const [forgotPasswordOTP, setForgotPasswordOTP] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });

  // Register State starts
  const [tokens, setTokens] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  });

  const tokenInputChangeHandler = (e, nextFocusField) => {
    const { name, value } = e.target;
    setTokens((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    if (nextFocusField != "sixth") {
      const nextfield = document.querySelector(`input[name=${nextFocusField}]`);

      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  };

  const [schoolDetails, setSchoolDetails] = useState();
  const [errorLoginMsg, setErrorLoginMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [key, setKey] = useState("");
  const forgotPasswordOtp = (e) => {
    const { name, value } = e.target;
    setForgotPasswordOTP((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const verifyTokenHandler = async () => {
    const allTokenCombine =
      tokens.first +
      tokens.second +
      tokens.third +
      tokens.fourth +
      tokens.fifth;
    // console.log(allTokenCombine);
    try {
      const res = await api.post("/auth/check/invitationCode", {
        code: allTokenCombine,
        portal: "teacher",
      });
      if (res && res.ok) {
        setMode("step-1");
        setSchoolDetails(res.data);
      }
    } catch (err) {
      toast.error("Invalid token");
    }
  };
  const [token, setToken] = useState("");

  const forgotPasswordOtpSubmit = async (e) => {
    e.preventDefault();
    const allOTPCombine =
      forgotPasswordOTP.first +
      forgotPasswordOTP.second +
      forgotPasswordOTP.third +
      forgotPasswordOTP.fourth +
      forgotPasswordOTP.fifth +
      forgotPasswordOTP.sixth;
    try {
      const res = await api.post("/auth/forgotpassword/email/token", {
        key: key,
        code: allOTPCombine,
      });

      if (res && res.ok) {
        setToken(res.token);
        setMode("step-7");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Step 3:-
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(true);
  const toggleNPasswordVisibility = () => {
    setNewPasswordVisibility(!newPasswordVisibility);
  };

  const [cNPasswordVisible, setCNPasswordVisibility] = useState(true);
  const toggleConfirmPasswordVisibility = () => {
    setCNPasswordVisibility(!cNPasswordVisible);
  };

  const [newPassword, setNewPassword] = useState({
    npassword: "",
    cpassword: "",
  });

  const forgotPasswordChangePasswordHandler = (e) => {
    const { name, value } = e.target;
    setNewPassword((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const onSubmitChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword.npassword !== newPassword.cpassword) {
      setErrorLoginMsg("Password and confirm password does't match");
      setTimeout(() => {
        setErrorLoginMsg("");
      }, 5000);
      return;
    } else {
      try {
        const res = await api.post(`/auth/changepassword`, {
          token: token,
          newPassword: newPassword.npassword,
        });

        if (res && res.ok) {
          toast.success(res.message);
          setTimeout(() => {
            setSuccessMsg("");
          }, 3000);
        }
      } catch (err) {
        toast.error(err.message);
        setTimeout(() => {
          setErrorLoginMsg("");
        }, 3000);
      }
    }
  };
  // End step 3

  // register user
  const [userDetails, setUserDetails] = useState({
    // email: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessage2, setErrorMessage2] = useState("");
  const userDetailsInputChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const registerUser = async (e, id) => {
    setErrorMessage2("");
    e.preventDefault();

    console.log(userDetails);
    if (!emailAdd) {
      setErrorMessage2("Please enter an email to register");
      return;
    }
    if (!userDetails.userName) {
      setErrorMessage2("Please enter an userName to register");
      return;
    }
    if (userDetails.password !== userDetails.confirmPassword) {
      setErrorMessage2("Password and confirm password do not match");
      return;
    }

    try {
      const res = await api.post("/auth/user-register", {
        email: emailAdd,
        name: userDetails.userName,
        password: userDetails.password,
        role: schoolDetails.role,
        subrole: schoolDetails.subrole,
        schoolId: schoolDetails.schoolId,
        status: "active",
      });

      if (res && res.ok) {
        toast.success("User register successfully.Please login to proceed.");
        // setMode('step-3')
        makeActive(id);
      }
    } catch (err) {
      setErrorMessage2(err.message);
    }
  };
  let loggedInUser = localStorage.getItem("userData");

  useEffect(() => {
    if (loggedInUser) {
      history.push("/dashboard");
    }
  }, [loggedInUser]);

  // steps for authentication

  // apis call
  const [recoveryEmail, setRecoveryEmail] = useState("");

  const [loginCreds, setLoginCreds] = useState({
    email: "",
    password: "",
    panel: "teacher",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");

  // change handler
  const changeHandler = (e) => {
    setLoginCreds((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const [mode, setMode] = useState("step-3");

  const clickHandler1 = (mode) => {
    // setMode('step-2')
  };
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
  });
  const clickHandler2 = async (e) => {
    // setMode('step-3')
    setErrorMessage("");
    e.preventDefault();
    // toast('Wow so easy !')
    try {
      const data = await api.post(`/auth/login`, {
        ...loginCreds,
      });
      if (data && data.ok) {
        localStorage.setItem("userData", JSON.stringify(data));
        toast.success(data.message);
        window.location.reload();
      }
    } catch (error) {
      setErrorMessage(error.message);

      consoleLog(error);
    }
  };
  const clickHandler3 = (mode) => {
    setMode("step-4");
  };
  const forgotPassword = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage1("");
      const data = await api.post("auth/forgotpassword/email/code", {
        email: recoveryEmail,
      });
      if (data && data.ok) {
        toast.success(data.message);
        setKey(data.key);

        setMode("step-5");
      }
    } catch (error) {
      console.log("error", error);
      setErrorMessage1(error.message);
    }
  };
  // toggle sign up and sign in
  const makeActive = (id) => {
    let option1 = document.getElementById("groupItem-1");

    let option2 = document.getElementById("groupItem-2");

    if (id === 1) {
      option1.classList.add("groupItem-active");
      option2.classList.remove("groupItem-active");
      // login is the below step
      setMode("step-3");
    } else {
      option1.classList.remove("groupItem-active");
      option2.classList.add("groupItem-active");
      setMode("step-1");
    }
  };
  // show password in register
  const [showregister1, setShowRegister1] = useState(false);
  const [showregister2, setShowRegister2] = useState(false);
  const [showlogin, setShowlogin] = useState(false);

  const showPasswordOfLogin = () => {
    setShowlogin(!showlogin);
    var x = document.getElementById("login");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const showPasswordOfRegister1 = () => {
    setShowRegister1(!showregister1);
    var x = document.getElementById("registerpassword1");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const showPasswordOfRegister2 = () => {
    setShowRegister2(!showregister2);
    var x = document.getElementById("registerpassword2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  // Check email
  const checkEmailHandler = async (e) => {
    e.preventDefault();
    try {
      const resData = await api.post("auth/check/email", {
        email: emailAdd,
        portal: "teacher",
      });
      if (resData && resData.ok) {
        setMode("step-6");
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      setErrorLoginMsg(err.message);
      setTimeout(() => {
        setErrorLoginMsg("");
      }, 3000);
    }
  };

  // end

  return (
    <>
      <div className="container-layout">
        <div className="main-content">
          <div className="main-content-left">
            <img className="topIcon" src={topLeftIcon} alt="TopLeft" />
            <div
              className="group-Item groupItem-active"
              id="groupItem-1"
              onClick={() => makeActive(1)}
            >
              <img src={signOut} alt="" />
              <span>Login</span>
            </div>
            <div
              className="group-Item"
              id="groupItem-2"
              onClick={() => {
                makeActive(2);
                setMode("email-check");
              }}
            >
              <img src={userEdit} alt="" />
              <span>Sign Up</span>
            </div>
          </div>
          <div className="great-place-edu">
            <div className="great-place-edu-inner">
              <img
                width={"140px"}
                style={{ margin: "2rem" }}
                className="stemIcon"
                src={stemUnicornIcon}
                alt="stemIcon"
              />
              <div style={{ marginTop: "2rem" }} className="text-Content">
                <div className="welcome-msg">Welcome!</div>
                <div className="description">A Great Place For Education.</div>
                {(mode === "step-4" || mode === "step-5") && (
                  <div className="forgotLogo-parent">
                    <img
                      className="forgotLogo"
                      src={forgotImage}
                      alt="forget"
                    />
                  </div>
                )}
              </div>

              <img className="sittingGirl" src={sittingGirl} alt="Girl" />
            </div>
          </div>
          <div className="main-content-right">
            {mode === "step-1" ? (
              <>
                <div className="heading">
                  Already have account?
                  <span
                    className="focused"
                    style={{ cursor: "pointer" }}
                    onClick={() => makeActive(1)}
                  >
                    {" "}
                    Login{" "}
                  </span>
                </div>
                <form onSubmit={(e) => registerUser(e, 1)}>
                  {errorMessage2 && (
                    <ErrorMessage message={errorMessage2} type="danger" />
                  )}
                  {/* <div className="group_Item">
                    <label htmlFor="">Email</label>
                    <div className="input-field">
                      <input
                        type="text"
                        name="email"
                        value={userDetails.email}
                        onChange={userDetailsInputChangeHandler}
                      />
                      <img src={Email} alt="" />
                    </div>
                  </div> */}
                  <div className="group_Item">
                    <label htmlFor="">Username</label>
                    <div className="input-field">
                      <input
                        type="text"
                        name="userName"
                        value={userDetails.userName}
                        onChange={userDetailsInputChangeHandler}
                      />
                      <img src={userName} alt="" />
                    </div>
                  </div>{" "}
                  <div className="group_Item">
                    <label htmlFor="">Password</label>
                    <div className="input-field">
                      <input
                        type="password"
                        name="password"
                        id="registerpassword1"
                        value={userDetails.password}
                        onChange={userDetailsInputChangeHandler}
                      />
                      <img
                        src={showregister1 ? eyeIcon : passwordHide}
                        onClick={showPasswordOfRegister1}
                        alt=""
                      />
                    </div>
                  </div>{" "}
                  <div className="group_Item">
                    <label htmlFor="">Confirm Password</label>
                    <div className="input-field">
                      <input
                        type="password"
                        name="confirmPassword"
                        id="registerpassword2"
                        value={userDetails.confirmPassword}
                        onChange={userDetailsInputChangeHandler}
                      />
                      <img
                        src={showregister2 ? eyeIcon : passwordHide}
                        onClick={showPasswordOfRegister2}
                        alt=""
                      />
                    </div>
                  </div>{" "}
                  <button
                    className="otpBtn"
                    style={{ marginTop: "20px" }}

                    // onClick={(mode) => clickHandler1(mode)}
                  >
                    Register
                  </button>
                </form>
              </>
            ) : mode === "step-2" ? (
              <div className="otp-Mode">
                <div className="heading">
                  OTP code has been sent to your Email.
                </div>
                <div className="otp-Numbers">
                  <input type="text" />
                  <input type="text" />
                  <input type="text" />
                  <input type="text" />
                  <input type="text" />
                  <input type="text" />
                </div>
                <div className="verify-btn">
                  <button
                    className="otpBtn otpBtn-1"
                    onClick={(mode) => clickHandler2(mode)}
                  >
                    Verify
                  </button>
                </div>
              </div>
            ) : mode === "email-check" ? (
              <>
                <div className="recover-Password">
                  <span className="forgotPasswordSpan">
                    Please enter your email address.
                  </span>
                  <form action="">
                    <div className="group_Item" style={{ marginTop: "53px" }}>
                      <label htmlFor="">Email</label>
                      <div className="input-field">
                        <input
                          type="text"
                          name="emailAdd"
                          value={emailAdd}
                          onChange={(e) => setEmailAdd(e.target.value)}
                        />
                        <img src="/static/images/Email.svg" alt="" />
                      </div>
                    </div>
                    <button
                      className="otpBtn"
                      style={{ marginTop: "150px" }}
                      type="submit"
                      onClick={(e) => checkEmailHandler(e)}
                    >
                      Send OTP
                    </button>
                  </form>
                </div>
              </>
            ) : mode === "step-6" ? (
              <div className="otp-Mode">
                <div className="heading text-center">
                  Enter invitation token
                </div>
                <div className="otp-Numbers">
                  <input
                    type="text"
                    name="first"
                    value={tokens.first}
                    onChange={(e) => tokenInputChangeHandler(e, "second")}
                    maxLength={1}
                  />
                  <input
                    type="text"
                    name="second"
                    value={tokens.second}
                    onChange={(e) => tokenInputChangeHandler(e, "third")}
                    maxLength={1}
                  />
                  <input
                    type="text"
                    name="third"
                    value={tokens.third}
                    onChange={(e) => tokenInputChangeHandler(e, "fourth")}
                    maxLength={1}
                  />
                  <input
                    type="text"
                    name="fourth"
                    value={tokens.fourth}
                    onChange={(e) => tokenInputChangeHandler(e, "fifth")}
                    maxLength={1}
                  />
                  <input
                    type="text"
                    name="fifth"
                    value={tokens.fifth}
                    onChange={(e) => tokenInputChangeHandler(e, "sixth")}
                    maxLength={1}
                  />
                </div>
                <div className="verify-btn">
                  <button
                    className="otpBtn otpBtn-1"
                    // onClick={(mode) => clickHandler2(mode)}
                    onClick={verifyTokenHandler}
                  >
                    Verify
                  </button>
                </div>
              </div>
            ) : mode === "step-3" ? (
              <>
                <div className="signIn-Mode">
                  <div className="heading">
                    Don’t have account?{" "}
                    <span
                      onClick={() => makeActive(2)}
                      style={{ cursor: "pointer" }}
                      className="focused"
                    >
                      Sign up
                    </span>
                  </div>
                  {errorMessage && (
                    <ErrorMessage message={errorMessage} type="danger" />
                  )}

                  <form onSubmit={clickHandler2}>
                    <div className="group_Item">
                      <label htmlFor="">Email</label>
                      <div className="input-field">
                        <input
                          type="email"
                          name="email"
                          placeholder=""
                          value={loginCreds.email}
                          onChange={changeHandler}
                        />
                        <img src={userName} alt="" />
                      </div>
                    </div>{" "}
                    <div className="group_Item">
                      <label htmlFor="">Password</label>
                      <div className="input-field">
                        <input
                          id="login"
                          type="password"
                          name="password"
                          value={loginCreds.password}
                          onChange={changeHandler}
                        />
                        <img
                          onClick={showPasswordOfLogin}
                          src={showlogin ? eyeIcon : passwordHide}
                          alt=""
                        />
                      </div>
                    </div>{" "}
                    <div
                      className="recover-pwd"
                      style={{ cursor: "pointer" }}
                      onClick={() => clickHandler3()}
                    >
                      Recover Password?
                    </div>{" "}
                    <button
                      type="submit"
                      className="otpBtn"
                      style={{ marginTop: "54px" }}
                      // onClick={(mode) => clickHandler2(mode, e)}
                    >
                      Login
                    </button>
                  </form>
                  <span className="agreement">
                    By clicking the Sign In, you agree to the terms and
                    conditions and the <span> privacy policy </span>.
                  </span>
                </div>
              </>
            ) : mode === "step-4" ? (
              <>
                <div className="recover-Password">
                  <div className="heading">Forgot Password</div>
                  <span className="forgotPasswordSpan">
                    Please enter your Email address.
                  </span>
                  {errorMessage1 && (
                    <ErrorMessage message={errorMessage1} type="danger" />
                  )}
                  <form onSubmit={forgotPassword}>
                    <div className="group_Item" style={{ marginTop: "53px" }}>
                      <label htmlFor="">Email</label>
                      <div className="input-field">
                        <input
                          type="email"
                          value={recoveryEmail}
                          onChange={(e) => setRecoveryEmail(e.target.value)}
                        />
                        <img src={Email} alt="" />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="otpBtn"
                      style={{ marginTop: "150px" }}
                      // onClick={(e) => clickHandler4(e)}
                    >
                      Send OTP
                    </button>
                  </form>
                  <span className="agreement agreement-1">
                    By clicking the Sign In, you agree to the terms and
                    conditions and the <span> privacy policy </span>.
                  </span>
                </div>
              </>
            ) : mode === "step-5" ? (
              <>
                <div className="otp-Mode otp-Mode-2">
                  <div className="heading" style={{ marginTop: "10px" }}>
                    Forgot Password?
                  </div>
                  <span className="forgotPasswordSpan">
                    OTP code has been sent to your Email.
                  </span>
                  <form onSubmit={forgotPasswordOtpSubmit}>
                    {/* </div> */}
                    <div
                      className="otp-Numbers"
                      style={{ marginBottom: "107px" }}
                    >
                      {console.log(forgotPasswordOTP)}
                      <input
                        type="text"
                        name="first"
                        value={forgotPasswordOTP.first}
                        onChange={forgotPasswordOtp}
                      />
                      <input
                        type="text"
                        name="second"
                        value={forgotPasswordOTP.second}
                        onChange={forgotPasswordOtp}
                      />
                      <input
                        type="text"
                        name="third"
                        value={forgotPasswordOTP.third}
                        onChange={forgotPasswordOtp}
                      />
                      <input
                        type="text"
                        name="fourth"
                        value={forgotPasswordOTP.fourth}
                        onChange={forgotPasswordOtp}
                      />
                      <input
                        type="text"
                        name="fifth"
                        value={forgotPasswordOTP.fifth}
                        onChange={forgotPasswordOtp}
                      />
                      <input
                        type="text"
                        name="sixth"
                        value={forgotPasswordOTP.sixth}
                        onChange={forgotPasswordOtp}
                      />
                    </div>
                    <button
                      className="otpBtn"
                      // style={{ marginTop: '150px' }}
                    >
                      Verify
                    </button>
                  </form>
                  {/* <span className='agreement agreement-1'>
                  By clicking the Sign In, you agree to the terms and conditions
                  and the <span> privacy policy </span>.
                </span> */}
                </div>
              </>
            ) : mode === "step-7" ? (
              <>
                <div className="otp-Mode otp-Mode-2">
                  <div className="heading" style={{ marginTop: "10px" }}>
                    Forgot Password?
                  </div>
                  <span className="forgotPasswordSpan">
                    Enter the new password
                  </span>
                  <form
                    action="
          
          "
                  >
                    {/* </div> */}
                    <div className="group_Item">
                      <label htmlFor="">Password</label>
                      <div className="input-field">
                        <input
                          type={newPasswordVisibility ? "password" : "text"}
                          name="npassword"
                          value={newPassword.npassword}
                          onChange={forgotPasswordChangePasswordHandler}
                        />
                        <img
                          src="/images/eyeIcon.svg"
                          alt=""
                          onClick={toggleNPasswordVisibility}
                        />
                      </div>
                    </div>{" "}
                    <div className="group_Item">
                      <label htmlFor="">Confirm Password</label>
                      <div className="input-field">
                        <input
                          type={cNPasswordVisible ? "password" : "text"}
                          name="cpassword"
                          value={newPassword.cpassword}
                          onChange={forgotPasswordChangePasswordHandler}
                        />
                        <img
                          src="/images/eyeIcon.svg"
                          alt=""
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      </div>
                    </div>{" "}
                    <button
                      className="otpBtn my-5"
                      // style={{ marginTop: '150px' }}
                      onClick={(e) => onSubmitChangePassword(e)}
                    >
                      Change Password
                    </button>
                  </form>
                  {/* <span className='agreement agreement-1'>
                  By clicking the Sign In, you agree to the terms and conditions
                  and the <span> privacy policy </span>.
                </span> */}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default Authentication;

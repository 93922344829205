import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  registerables as registerablesJS,
} from "chart.js";
import { Link } from "react-router-dom";

// import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { AnalyticsModal } from "./GlobalDialog";
import ReactApexChart from "react-apexcharts";

import api from "../services/api";
import { consoleLog } from "../utils/helper";
ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const AnalyticsLesson = ({ grades, timeSpent, classId }) => {
  let schoolId = JSON.parse(localStorage.getItem("userData")).user.schoolId
    ?._id;

  let gradient;
  let gradient1;
  const [gradientValue, setGradientValue] = useState("");
  const [gradientValue1, setGradientValue1] = useState("");

  // useEffect(() => {
  //   var canvas = document.getElementById("distributionCurve");
  //   var ctx = canvas.getContext("2d");
  //   gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  //   gradient.addColorStop(0.33, "#EF5B2C");

  //   gradient.addColorStop(1, "#FED2C4");
  //   setGradientValue(gradient);
  // }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          zeroLineColor: "transparent",
        },
      },
      xAxes: {
        display: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
          zeroLineColor: "transparent",
        },
      },
    },

    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  // options object for modal
  const optionsWeeklyModal = {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      point: {
        radius: 1,
      },
    },
    scales: {
      y1: {
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Time Taken",
        },
        ticks: {
          display: true,
        },
        grid: {
          display: false,
          zeroLineColor: "transparent",
        },
      },

      yAxes: {
        display: true,
        position: "right",
        ticks: {
          display: true,
        },
        title: {
          display: true,
          text: "Correct Rate",
        },
        grid: {
          display: false,
          zeroLineColor: "transparent",
        },
      },
      xAxes: {
        display: true,
        ticks: {
          display: true,
        },
        title: {
          display: true,
          text: "Questions",
        },
        grid: {
          display: false,
          zeroLineColor: "transparent",
        },
      },
    },

    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const options3 = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
      },
    },
    scales: {
      yAxes: {
        display: true,
        ticks: {
          display: false,
        },
        gridLines: {
          display: true,
          zeroLineColor: "transparent",
        },
        zeroLineColor: "transparent",
      },
      xAxes: {
        display: false,
        ticks: {
          display: false,
        },
        gridLines: {
          display: true,
          zeroLineColor: "transparent",
        },
      },
    },

    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const options2 = {
    // responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
      },
    },
    scales: {
      yAxes: {
        display: true,
        ticks: {
          display: false,
        },
        gridLines: {
          display: true,
          zeroLineColor: "transparent",
        },
        zeroLineColor: "transparent",
      },
      xAxes: {
        display: false,
        ticks: {
          display: false,
        },
        gridLines: {
          display: true,
          zeroLineColor: "transparent",
        },
      },
    },

    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const labels1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const series = [70];
  const options1 = {
    chart: {
      type: "radialBar",
      height: "220px",
      width: "220px",
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#000000",
            lineHeight: "15px",

            fontSize: "12px",
            fontWeight: "400",
          },
          value: {
            color: "#000000",

            show: true,
            fontWeight: 400,
            fontSize: "36px",
            lineHeight: "44px",
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#979797",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
      },
    },
    colors: ["#EF5B2C", "#0090FF"],
    stroke: {
      lineCap: "round",
    },
    labels: ["Average Score"],
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [10, 15, 20, 5, 30, 150, 90, 20, 70, 5],
        borderColor: "transparent",
        backgroundColor: gradientValue,
        fill: true,
        tension: 0.5,
      },
    ],
  };
  const datanew = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [10, 15, 20, 5, 30, 150, 90, 20, 70, 5],
        borderColor: "#5AA4E3",
        // backgroundColor: "white",
        // fill: true,
        // tension: 0.5,
      },
    ],
  };
  // const data1 = {
  //   labels: labels1,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: [25, 10, 15, 50, 50, 40, 40, 20, 11, 5],
  //       borderColor: '#0090FF',
  //       backgroundColor: '#0090FF',
  //       tension: 0.5,
  //     },
  //   ],
  // }
  // get data for graph
  const graphData = (data) => {
    let labelsIndexes = [];
    let dataValues = [];

    data &&
      data.length > 0 &&
      data.map((item, id) => {
        labelsIndexes.push(id + 1);
        dataValues.push(item.noOfCorrrect);
      });
    return {
      labels: labelsIndexes,
      datasets: [
        {
          label: "Dataset 1",
          data: dataValues,
          borderColor: "#0090FF",
          backgroundColor: "#0090FF",
          tension: 0.5,
        },
      ],
    };
  };
  const data4 = {
    labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    datasets: [
      {
        type: "line",
        label: "Bar Dataset",
        data: [100, 20, 30, 40, 110, 190, 300, 201, 202, 0],
        borderColor: "rgba(239, 91, 44, 0.4)",
        backgroundColor: "rgba(54, 54, 54, 0.4)",
        borderWidth: 2,
        // fill: true,
        // pointRadius: 0,
      },
      {
        type: "line",
        label: "Line Dataset",
        data: [5, 10, 10, 40, 30, 60, 40, 30, 100, 20],
        backgroundColor: "gradientValue1",
        borderColor: "rgba(239, 91, 44, 0.4)",
        backgroundColor: "rgba(54, 54, 54, 0.4)",
        borderWidth: 2,
        fill: false,

        // pointRadius: 0,
      },
      {
        type: "line",
        label: "Line Dataset",
        data: [5, 10, 10, 40, 30, 60, 40, 30, 100, 20],
        fill: false,
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 2,
        yAxisID: "y1",

        // pointRadius: 0,
      },
    ],
  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const openModal = (id) => {
    setOpen(true);
    setSelectedWeek(id);
  };

  const closeModal = () => {
    setOpen(false);
  };
  // get analytics

  // const getAnalytics=async()=>{
  //   try {
  //     const data=await api.get(`/teacher/quizAnalytics`)

  //   } catch (error) {

  //   }
  // }

  // get weeks

  const [selectedweek, setSelectedWeek] = useState();

  function converter(seconds) {
    var days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * (24 * 60 * 60);
    var hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * (60 * 60);
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return (
      (0 < days ? days + " day, " : "") +
      (0 < hours ? hours + "h, " : "") +
      (0 < minutes ? minutes + "m and " : "") +
      seconds +
      "s"
    );
  }
  const [avgDistributiondata, setavgDistributiondata] = useState({
    labels: [],
    datasets: [],
  });
  const averageCorrectDistribution = async () => {
    try {
      const data = await api.get(
        `/teacher/avg/correct/distrubution/graph/${classId}`
      );
      if (data && data.ok) {
        console.log("data of avg dist", data);
        let labels = [];
        let data1 = [];
        if (data?.data[0].length > 0 && data?.data[1].length > 0) {
          labels = [...data?.data[1]];
          data1 = [...data?.data[0]];
          setavgDistributiondata({
            labels: labels,
            datasets: [
              {
                label: "Correct Rate",
                data: data1,
                borderColor: "transparent",
                // backgroundColor: gradientValue,
                borderColor: "#EF5B2C",

                fill: true,
                tension: 0.5,
              },
            ],
          });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // avergage quiz performance
  const [avgQuizPerformance, setavgQuizPerformance] = useState({
    labels: [],
    data: [],
  });
  const averageQuizPerformance = async () => {
    try {
      const data = await api.get(
        `/teacher/overall/quiz/performance/graph/${classId}/${schoolId}`
      );
      if (data && data.ok) {
        console.log("data of avg quiz", data);
        let labels = [];
        let data1 = [];
        if (data.data.length > 0) {
          // labels = [...data?.data[1]]
          data?.data[0].map((datum, idx) => {
            labels.push(
              datum?.user[0]?.name ? datum?.user[0]?.name : "someone"
            );
            data1.push(datum?.avgGrade ? datum?.avgGrade : 0);
          });
          setavgQuizPerformance({
            labels: labels,
            data: data1,
          });
          console.log({ labels: labels, data: data1 });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (classId) {
      averageCorrectDistribution();
      averageQuizPerformance();
    }
  }, [classId]);

  // for radial
  const radialOptions = {
    chart: {
      type: "radialBar",
      height: "220px",
      width: "220px",
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#000000",
            lineHeight: "15px",

            fontSize: "12px",
            fontWeight: "400",
          },
          value: {
            color: "#000000",

            show: true,
            fontWeight: 400,
            fontSize: "36px",
            lineHeight: "44px",
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#979797",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
      },
    },
    colors: ["#DC4C8F", "#ffffff"],
    stroke: {
      lineCap: "round",
    },
    labels: ["Average Correct Rate"],
  };
  // weekly data
  const [weeklyData, setWeeklyData] = useState();
  const getWeeklyData = async () => {
    try {
      const data = await api.get(
        `/teacher/view/class/content/response/${classId}`
      );
      console.log("data is", data);
      if (data && data.ok) {
        setWeeklyData(data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    if (classId) {
      getWeeklyData();
    } else {
      setWeeklyData();
    }
  }, [classId]);
  const barData = (data) => {
    let labels = [];
    let datas = [];
    if (data.length > 0) {
      data.map((datum, idx) => {
        labels.push(idx + 1);
        datas.push(parseInt(datum?.noOfCorrrect));
      });
      //     labels: labels1,
      // datasets: [
      //   {
      //     label: 'Dataset 1',
      //     data: [25, 10, 15, 50, 50, 40, 40, 20, 11, 5],
      //     borderColor: '#BCBCBC',
      //     backgroundColor: '#BCBCBC',
      //     tension: 0.5,
      //   },
      // ],
      return {
        labels,
        datasets: [
          {
            label: "No Of Correct",
            data: datas,
            borderColor: "#0090FF",
            backgroundColor: "#0090FF",
            tension: 0.5,
            borderRadius: 35,
            borderSkipped: false,
          },
        ],
      };
    }

    return {
      labels,
      datasets: [
        {
          label: "No Of Correct",
          data: datas,
          borderColor: "#BCBCBC",
          backgroundColor: "#BCBCBC",
          tension: 0.5,
        },
      ],
    };
  };

  const [weeklyModelData, setweeklyModelData] = useState({
    labels: [],
    datasets: [],
  });
  const weekPerformance = async () => {
    try {
      const data = await api.get(
        `/teacher/week/performance/summary/${classId}/${selectedweek}`
      );
      if (data && data.ok) {
        console.log("data of weeklymodel", data);
        let labels = [];
        let data1 = [];
        let data2 = [];
        console.log("data here first", data.averageTime, data.totalAvgCorrect);
        if (data?.averageTime.length > 0 && data?.totalAvgCorrect.length > 0) {
          data.averageTime.map((timus, index) => {
            labels.push(index + 1);
            data1.push(timus.time ? timus.time : 0);
          });
          data.totalAvgCorrect.map((question, idx) =>
            data2.push(question !== null ? question * 100 : 0)
          );
          // data2 = [...data?.totalAvgCorrect]

          setweeklyModelData({
            labels: labels,
            datasets: [
              {
                type: "line",
                label: "Time Taken",
                data: data1,
                fill: false,
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgb(54, 162, 235)",

                borderWidth: 2,
                yAxisID: "y1",

                pointRadius: 1,
              },
              {
                type: "line",
                label: "Correct Rate",
                data: data2,

                borderColor: "rgba(239, 91, 44, 0.4)",
                backgroundColor: "rgba(239, 91, 44, 0.4)",
                borderWidth: 2,
                fill: false,

                pointRadius: 1,
                yAxisID: "yAxes",
              },
            ],
          });
        } else {
          setweeklyModelData({
            labels: [],
            datasets: [],
          });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (selectedweek && classId) {
      weekPerformance();
    }
  }, [selectedweek, classId]);

  console.log(weeklyData);

  //

  const barData2 = (data) => {
    let labels = [];
    let datas = [];
    data.labels.map((labelData) => {
      labels.push(labelData);
    });
    data?.datasets[0]?.data.map((percentageData) => {
      datas.push(percentageData * 100);
    });
    return {
      labels,
      datasets: [
        {
          label: "Average correct rate",
          data: datas,
          borderColor: "#0090FF",
          backgroundColor: "#0090FF",
        },
      ],
    };
  };

  const optionsForACRD = {
    plugins: {
      title: {
        display: false,
        text: "Average Correct Rate",
      },
      legend: {
        position: "bottom",
        display: false,
      },
    },
    responsive: true,
    scales: {
      y1: {
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Average student results",
        },
        ticks: {
          display: true,
        },
        grid: {
          display: true,
          zeroLineColor: "transparent",
        },
      },
    },
  };

  const QOAPOption = {
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0.2,
    },
  };

  return (
    <div>
      <AnalyticsModal
        // type='upload'
        open={open}
        close={closeModal}
        heading="Module questions analytics"
      >
        <Bar
          data={weeklyModelData && weeklyModelData}
          options={optionsWeeklyModal}
        />
        <div className="back-btn-1">
          <button
            className="btn-analytics"
            onClick={closeModal}
            style={{
              background: "#625F5F",
              borderRadius: "6px",
              color: "white",
            }}
          >
            Back
          </button>
        </div>
      </AnalyticsModal>
      <div className="tabpanel-content">
        <span className="header">Quiz questions analytics</span>
        <div className="top-section">
          <div className="top-section-left ">
            <div className="top-section-left-top spacing">
              <div className="upper">Average grade</div>
              <div className="lower">{grades} %</div>
            </div>
            <div className="top-section-left-bottom spacing ">
              <div className="upper">Average time spent on each module</div>
              <div className="lower">{timeSpent}</div>
            </div>
          </div>
          <div className="top-section-middle spacing">
            <div className="upper">Average correct rate</div>
            <div className="lower">
              {/* <Line
                // height='200px'
                // width='200px'
                id="distributionCurve"
                options={options2}
                data={avgDistributiondata && avgDistributiondata}
              /> */}
              <Bar
                options={optionsForACRD}
                data={barData2(avgDistributiondata)}
              />
            </div>
          </div>
          <div className="top-section-right spacing">
            <div className="upper">Overall Quiz Performance</div>
            {/* <div className="lower"> */}
            {/* <Line
                // height='200px'
                // width='200px'
                options={options3}
                data={avgQuizPerformance && avgQuizPerformance}
              /> */}
            {typeof window !== "undefined" ? (
              <ReactApexChart
                options={{
                  QOAPOption,
                  labels: avgQuizPerformance.labels,
                }}
                series={avgQuizPerformance.data}
                type="pie"
                width={400}
              />
            ) : (
              ""
            )}
            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="bottom-section">
        <div className="left">
          <span className="performance">Module Peformance</span>
          {/* <button className="btn-analytics">Summary</button> */}
        </div>
      </div>

      {/* {weeklyDataStats !== undefined && (
        <div className='most-bottom'>
          <div className='most-bottom-left'>
            <div className='header-section'>
              <div className='header'>
                {weeklyDataStats?.contentDetail[0]?.title}
              </div>
              <div className='middle-content'>
                <button
                  className='btn-analytics'
                  disabled={true}
                  onClick={() =>
                    openModal(weeklyDataStats?.contentDetail[0]._id)
                  }
                >
                  Summary
                </button>
                <Link
                  to={`/analytics/details/${classId}/${weeklyDataStats?.contentDetail[0]._id}`}
                >
                  <button className='btn-analytics'>Details</button>
                </Link>
              </div>
            </div>
            <div className='middle-section'>
              <div className='left'>
                <Chart
                  options={options1}
                  series={[
                    (weeklyDataStats?.averageCorrectRate * 100).toFixed(1),
                  ]}
                  type='radialBar'
                  height={220}
                  width={220}
                />
                <div className='info'>
                  <span>Average Time Spent</span>
                  <span>
                    {converter(
                      weeklyDataStats?.averageTime[0]?.week?.avgModuleTime
                    )}
                  </span>
                </div>
                <div className='info'>
                  <span>Avg. time / question</span>
                  <span>
                    {converter(
                      weeklyDataStats?.averageTime[0]?.week?.avgQuizTime
                    )}
                  </span>
                </div>
              </div>
              <div className='right'>
                <div className='heading'>Questions Performance</div>
                <Bar
                  options={options}
                  data={graphData(weeklyDataStats?.quizResponse[0].quiz)}
                  // width={500}
                  height={180}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
      <div className="most-bottom">
        {weeklyData &&
          weeklyData.quizResponse &&
          weeklyData.quizResponse.length > 0 &&
          weeklyData.quizResponse.map((week, index) => (
            <div key={index} className="most-bottom-left">
              <div className="header-section">
                <div className="header">{week?.title} </div>
                <div className="middle-content">
                  <button
                    className={`btn-analytics ${
                      index % 2 === 0
                        ? "btn-analytics-new"
                        : "btn-analytics-new1"
                    }`}
                    onClick={() => openModal(week._id)}
                  >
                    Summary
                  </button>
                  <Link to={`/analytics/details/${classId}/${week._id}`}>
                    <button
                      className={`btn-analytics ${
                        index % 2 === 0
                          ? "btn-analytics-new"
                          : "btn-analytics-new1"
                      }`}
                    >
                      Details
                    </button>
                  </Link>
                </div>
              </div>
              <div className="middle-section">
                <div className="left">
                  <Chart
                    // options={radialOptions}
                    options={
                      index % 2 === 0
                        ? radialOptions
                        : {
                            ...radialOptions,
                            colors: ["#6A51BE", "#ffffff"],
                          }
                    }
                    series={[
                      (weeklyData?.totalAvgCorrect[index] * 100).toFixed(1),
                    ]}
                    type="radialBar"
                    height={220}
                    width={220}
                  />
                  <div className="info">
                    <span>Average Time Spent</span>
                    <span>
                      {weeklyData?.averageSubmittionTime[index]?.avgOverallTime
                        ? (weeklyData?.averageSubmittionTime[
                            index
                          ]?.avgOverallTime).toFixed(1)
                        : 0}{" "}
                      seconds
                      {/* {' '}
                 {item?.time?.avgOverallTime + ' seconds' ||
                   'No analyzed Yet'} */}
                    </span>
                  </div>
                  <div className="info">
                    <span>Avg. time / question</span>
                    <span>
                      {weeklyData?.averageSubmittionTime[index]?.avgQuizTime
                        ? (
                            weeklyData?.averageSubmittionTime[index]
                              ?.avgQuizTime - 1
                          ).toFixed(1)
                        : 0}{" "}
                      seconds
                      {/* {item?.time?.avgQuizTime + ' seconds' ||
                   'No analyzed Yet'} */}
                    </span>
                  </div>
                </div>
                <div className="right">
                  <div className="heading">Questions Performance</div>

                  <Bar
                    options={options}
                    data={barData(week?.quiz)}
                    // data={data1}
                    width={500}
                    height={180}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AnalyticsLesson;

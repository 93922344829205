import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopContent from "../../components/TopContent";
import calendarClassroom from "../../assets/calendarClassroom.svg";
import message from "../../assets/message.svg";
import people from "../../assets/people.svg";
import ErrorMessage from "../../components/ErrorMessage";
import TableMain from "../../components/Table";
import { ToastContainer, toast } from "react-toastify";

import search from "../../assets/search.svg";
import girl from "../../assets/girl.svg";

import switchIcon from "../../assets/switch.svg";
import close from "../../assets/close.svg";

import { GlobalDialog } from "../../components/GlobalDialog";
import BreadCrumbList from "../../components/BreadCrumbList";
import api from "../../services/api";
import RightSidebar from "../../components/RightSidebar";
import LoadingSpinner from "../../components/LoaderSpinner";

const ClassRoom = ({ match }) => {
  // search
  const [searchItem, setSearchItem] = useState("");
  const performSearch = () => {
    if (searchItem === "") {
      setStudents(initialStudents);
    } else {
      let found = students.filter((item) =>
        item.name.toLowerCase().includes(searchItem.toLowerCase())
      );
      setStudents(found);
    }
  };
  useEffect(() => {
    performSearch();
  }, [searchItem]);

  // get data
  const [refresh, setRefresh] = useState(false);
  const [students, setStudents] = useState([]);
  const [initialStudents, setInitialStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [classContents, setClassContents] = useState([]);

  const getClassDetails = async () => {
    try {
      const data = await api.get(
        `/teacher/view/all/class/${match.params.id}/student`
      );
      if (data && data.ok) {
        data &&
          data.data[0].forEach((element) => {
            element.class = data.data[0].className;
          });
        setInitialStudents(data.data[0]);

        setStudents(data.data[0]);
        // total students
        setTotalStudents(data.data[0].length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClassDetails();
    fetchClassContent();
  }, [match, refresh]);

  const fetchClassContent = async () => {
    const res = await api.post(
      `/teacher/classroomContents/view/${match.params.id}`
    );
    if (res && res.ok) {
      setClassContents(res.data);
      console.log(res.data);
    }
  };

  // console.log(students);
  // console.log(classContents);

  // breadcrumbs
  const values = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
  ];
  const headCells = [
    {
      id: "name",
      label: "Name",
      numeric: false,
      disablePadding: false,
    },
    // {
    //   id: "id",
    //   label: "ID",
    //   numeric: false,
    //   disablePadding: false,
    // },
    // {
    //   id: "class",
    //   label: "Class",
    //   numeric: false,
    //   disablePadding: false,
    // },
    {
      id: "email",
      label: "Email",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "button",
      label: "",
      numeric: false,
      disablePadding: false,
    },
  ];

  const clasContentHeading = [
    {
      id: "title",
      label: "Title",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "",
      label: "",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "Unlock time",
      label: "Unlock Time",
      numeric: false,
      disablePadding: false,
    },
  ];
  const data = [
    {
      intro: { name: "Riyaa ", image: girl },
      id: "ABC123",
      class: "A",
      email: "Riya@gmail.com",
    },
    {
      intro: { name: "Riyaa ", image: girl },

      id: "ABC123",
      class: "A",
      email: "Riya@gmail.com",
    },
    {
      intro: { name: "Riyaa ", image: girl },

      id: "ABC123",
      class: "A",
      email: "Riya@gmail.com",
    },
    {
      intro: { name: "Riyaa ", image: girl },

      id: "ABC123",
      class: "A",
      email: "Riya@gmail.com",
    },
  ];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    setEmail("");
    setClassroomCode("");

    setClassfile("");
  };
  // form fields
  const [email, setEmail] = useState("");
  const [classroomCode, setClassroomCode] = useState("");
  const [classfile, setClassfile] = useState("");
  // error handling
  const [errors, setErrors] = useState({});
  const validateForm = (values) => {
    let errors = {};
    if (!values.classroomCode) {
      errors.classroomCode = "classroomCode is Required";
    }
    if (!values.email) {
      errors.email = "email is Required";
    }

    return errors;
  };
  // invite handle
  // const handleInvite = async () => {
  //   const event = {
  //     classroomCode,
  //     email,
  //   };
  //   setErrors(validateForm(event));
  //   if (Object.keys(validateForm(event)).length > 0) {
  //     return;
  //   } else
  //     try {
  //       if (classfile) {
  //         const formData = new FormData();

  //         formData.append("classfile", classfile);
  //         const data = await api.post("/teacher/upload/class", formData);
  //         if (data.ok) {
  //           toast.success(data.message);
  //           setEmail("");
  //           setClassroomCode("");
  //           setClassfile("");
  //           setOpen(false);
  //         }
  //       } else {
  //         const data = await api.post("/teacher/add/student/classroom", {
  //           classRoomId: classroomCode,
  //           studentEmail: email,
  //         });
  //         if (data.ok) {
  //           toast.success(data.message);
  //           setEmail("");
  //           setClassroomCode("");
  //           setOpen(false);
  //           setClassfile("");
  //         }
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //       toast.error(error.message);
  //     }
  // };

  const [apiLoader, setApiLoader] = useState(false);
  const [singleEmailInvite, setSingleEmailInvite] = useState("");

  const handleInvite = async () => {
    try {
      setApiLoader(true);
      if (singleEmailInvite != "") {
        const res1 = await api.post(
          `teacher/addSingleStudent/${match.params.id}`,
          { email: singleEmailInvite }
        );
        if (res1 && res1.ok) {
          setApiLoader(false);
          handleClose();
          window.location.reload();
        }
      } else {
        const data = new FormData();
        data.append("file", classfile);

        const res = await api.post(
          `teacher/bulkStudentAddToClassroom/${match.params.id}`,
          data
        );
        if (res && res.ok) {
          setApiLoader(false);
          handleClose();
          window.location.reload();
        }
      }
    } catch (err) {
      setApiLoader(false);
      console.error(err);
    }
  };

  // csv upload
  const handleCsv = (e) => {
    console.log("file is", e.target.files[0]);
    setClassfile(e.target.files[0]);
  };

  const [pageState, setPageState] = useState(0);

  return (
    <div className="mainContainer">
      {apiLoader ? <LoadingSpinner /> : ""}
      <GlobalDialog open={open} handleClose={handleClose}>
        <div className="dialog-invite">
          <div className="header d-flex-row">
            <span>Invite</span>
            <img
              src={close}
              style={{ cursor: "pointer" }}
              alt="Close"
              onClick={handleClose}
            />
          </div>
          <div className="main">
            {/* <div className='groupItem-invite'>
              <label htmlFor=''>classroom Name</label>
              <input
                name='classroomCode'
                value={classroomCode}
                onChange={(e) => setClassroomCode(e.target.value)}
                type='text'
              />
            </div>
            <ErrorMessage message={errors.classroomCode} type='danger' /> */}

            {/* <div className='groupItem-invite'>
              <label htmlFor=''>student Email</label>
              <input
                type='text'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <ErrorMessage message={errors.email} type='danger' /> */}

            <div className="groupItem-invite">
              <label htmlFor="">Single Invite</label>
              <input
                type="text"
                name="email"
                placeholder="Enter email address"
                value={singleEmailInvite}
                onChange={(e) => setSingleEmailInvite(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <label>Or,</label>
              </div>
              <label htmlFor="">Upload CSV</label>
              <label htmlFor="Upload" className="upload-btn">
                Add
              </label>
              <input
                id="Upload"
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={(e) => handleCsv(e)}
                // name='classroomCode'
                // value={classroomCode}
                // onChange={(e) => setClassroomCode(e.target.value)}
              />
              <span style={{ textAlign: "center", display: "block" }}>
                {classfile.name}
              </span>
            </div>
            <div className="btns">
              <button className="cancel-btn" onClick={handleClose}>
                Cancel
              </button>
              <button className="invite-btn" onClick={handleInvite}>
                Save
              </button>
            </div>
          </div>
        </div>
      </GlobalDialog>
      <div className="mainDashboard">
        <Sidebar section="home" />
        <div className="main_section">
          <div className="main_section_inner">
            {/* <TopContent /> */}
            <div className="middle-Content middle-Content-Classroom">
              <div className="heading-classroom">
                <BreadCrumbList values={values} />
              </div>
              <div className="top-classroom">
                <div className="left">
                  {/* <div className="inner-box">
                    <img src={calendarClassroom} alt="Calendar" />
                    <div className="content">
                      <span className="top">License renew date</span>
                      <span>2022 - 09 - 30</span>
                    </div>
                  </div> */}
                  <div className="inner-box">
                    <img src={message} alt="Calendar" />
                    <div className="content">
                      <span className="top">Registered Students</span>
                      <span>{totalStudents}</span>
                    </div>
                  </div>
                  <div className="inner-box">
                    <img src={people} alt="Calendar" />
                    <div className="content">
                      <span className="top">Language of use</span>
                      <span>English (US)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-Content-classroom">
                <div className="top d-flex-row">
                  <span className="heading ">Student</span>
                  <span className="invite" onClick={handleOpen}>
                    Invite
                  </span>
                </div>
              </div>

              <div className="table-Content">
                <div className="top d-flex-row">
                  <div
                    style={{
                      display: "flex",
                      // background: "red",
                      // width: "100px",
                    }}
                  >
                    <div
                      // style={{ cursor: "pointer" }}
                      className="heading"
                      onClick={() => setPageState(0)}
                      style={
                        pageState === 0
                          ? {
                              paddingBottom: "10px",
                              borderBottom: "2px #EF5B2C solid",
                              cursor: "pointer",
                            }
                          : { cursor: "pointer", paddingBottom: "10px" }
                      }
                    >
                      Student
                    </div>
                    <div
                      className="heading"
                      // style={{ marginLeft: "20px", cursor: "pointer" }}
                      onClick={() => setPageState(1)}
                      style={
                        pageState === 1
                          ? {
                              paddingBottom: "10px",
                              borderBottom: "2px #EF5B2C solid",
                              marginLeft: "20px",
                              cursor: "pointer",
                            }
                          : {
                              marginLeft: "20px",
                              cursor: "pointer",
                              paddingBottom: "10px",
                            }
                      }
                    >
                      Class content
                    </div>
                  </div>
                  <div className="right_Content d-flex-row">
                    <div className="left">
                      <input
                        type="text"
                        name="searchItem"
                        value={searchItem}
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder="Search..."
                      />
                      <img src={search} alt="" />
                    </div>
                    {/* <div className="right">
                      <img src={switchIcon} alt="" />
                    </div> */}
                  </div>
                </div>
                <div className="underline">
                  {/* <div className="left"></div> */}
                  <div className="right"></div>
                </div>

                {pageState ? (
                  <TableMain
                    headCells={clasContentHeading}
                    resultData={classContents}
                    topic="Classcontent"
                    setRefresh={setRefresh}
                    refresh={refresh}
                    classId={match.params.id}
                    label={"left"}
                  />
                ) : (
                  <TableMain
                    headCells={headCells}
                    resultData={students}
                    topic="classroomCell"
                    setRefresh={setRefresh}
                    refresh={refresh}
                    classId={match.params.id}
                  />
                )}
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassRoom;
